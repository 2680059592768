<template>
  <v-card class="game-setting">
    <v-card-text>
      <v-toolbar color="highlight" dark>
        <v-icon v-if="copying">open_in_new</v-icon>

        <v-icon v-else dark>build</v-icon>

        <v-toolbar-title> {{ game.name }} </v-toolbar-title>
        <v-spacer />
        <v-spacer />
        <v-btn icon @click="update">
          <v-icon>saves</v-icon>
        </v-btn>
        <v-btn icon @click="close()">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs v-if="game" v-model="activeSettingsTab">
        <v-tab key="general" href="#general">Overview</v-tab>
        <v-tab key="details" href="#details">Expo</v-tab>
        <v-tab key="styles" href="#styles">Styles</v-tab>
        <v-tab key="match-emails" href="#match-emails">RR Match Emails</v-tab>
        <v-tab-item key="general" value="general">
          <div>
            <v-layout row wrap>
              <v-flex xs12 sm4 mx-2>
                <v-text-field
                  v-model="game.name"
                  label="Internal Name"
                  required
                />
              </v-flex>
              <v-flex xs12 sm5 mx-2>
                <v-text-field
                  v-model="game.externalName"
                  label="External Name"
                  required
                />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-select
                  v-model="game.hostUserID"
                  :items="hosts"
                  label="Host"
                  item-text="theName"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs12 sm4 mx-2>
                <v-text-field
                  v-model="game.description"
                  label="Description"
                  required
                />
              </v-flex>

              <v-flex v-if="game.runStatus == 'Masters'" xs12 sm1 mx-2>
                <v-text-field v-model="game.version" label="Version #" />
              </v-flex>
              <v-flex xs5 mx-2>
                <v-text-field
                  v-model="game.pickTeamText"
                  label="Pick Team Text"
                  required
                />
              </v-flex>
              <v-flex xs2 mx-2>
                <v-text-field
                  v-model="game.latecomerVideoCode"
                  label="Later Comer Video"
                />
              </v-flex>
              <v-flex xs5 mx-2>
                <v-text-field v-model="game.client" label="Client" required />
              </v-flex>
              <v-flex xs4 mx-2>
                <v-text-field v-model="game.date" label="Game Date" required />
              </v-flex>

              <v-flex xs5 sm2 mx-2>
                <v-select
                  :items="listTabs"
                  v-model="game.runStatus"
                  label="Game Status"
                  required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.locked" label="Locked" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.started" label="Started" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.logrocket" label="LogRocket" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.timerOff" label="Timer Off" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-switch
                      v-on="on"
                      v-model="game.regularLogin"
                      label="Email Login"
                    />
                  </template>
                  <span>Email Login can be enabled in Client Settings </span>
                </v-tooltip>
              </v-flex>
              <v-flex class="liftup" xs12 sm2 mx-2>
                <v-switch v-model="game.tipsDisabled" label="Disable Tips" />
              </v-flex>
              <v-flex class="liftup" xs12 sm2 mx-2>
                <v-switch v-model="game.onboardChat" label="Onboard Chat" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-text-field v-model="game.potato" label="Potato" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-text-field v-model="game.players" label="Num of players" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <datepicker v-model="gameDate" :placeholder="'Game Time'" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <vue-timepicker v-model="gameTime" format="HH:mm:ss" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-btn @click="logout()">Logout</v-btn>
              </v-flex>
              <v-flex xs12 sm2 mx-2 v-if="!!endTimeText">
                End Time: {{ endTimeText }}
                <!-- <v-text-field type="number" v-model="" label="End Time" /> -->
              </v-flex>
            </v-layout>
            <v-flex v-if="user.super && copying" xs12 mx-2>
              <v-select
                v-model="copyToOrgID"
                :items="orgs"
                label="Copy To Organization"
                item-text="name"
                item-value="id"
              />
            </v-flex>
          </div>
        </v-tab-item>
        <v-tab-item key="styles" value="styles" class="pa-4">
          <StylesEditor
            :styles="getStyles(game)"
            @input="onStylesInput"
            class="mb-2"
          />
          {{ game.themeID }}
          <v-select
            v-model="game.themeID"
            :items="themes"
            label="Theme"
            class="max-w-xs"
          ></v-select>
          <v-btn color="red" @click="deleteTheme">Delete Theme</v-btn>
        </v-tab-item>
        <v-tab-item key="details" value="details">
          <div>
            <v-layout row wrap>
              <v-flex xs12 sm3 mx-2>
                <v-select
                  v-model="gameType"
                  :items="gameTypes"
                  label="Game Type"
                />
              </v-flex>

              <v-flex v-if="gameType == 'YouTube'" xs12 sm4 mx-2>
                <v-text-field
                  sm5
                  v-model="game.youTubeUrl"
                  label="YouTube Url"
                />
              </v-flex>
              <v-flex v-if="gameType == 'YouTube'" xs12 sm3 mx-2>
                <v-switch
                  sm6
                  v-model="game.youTubeAutoPlay"
                  style="margin-top: -3px"
                  label="Auto Play"
                />
              </v-flex>p
              <v-flex v-if="gameType == 'Twitch'" xs12 sm8 mx-2>
                <v-text-field
                  v-model="game.twitchChannel"
                  label="Twitch Channel"
                />
              </v-flex>

              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.auditable" label="Auditable" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.deactivate" label="Deactivate" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.ondeck" label="Staging" />
              </v-flex>

              <v-flex xs3 mx-2>
                <v-text-field
                  v-model.number="normalizedGameDuration"
                  label="Duration Mins"
                />
              </v-flex>

              <v-flex xs12 sm3 mx-2>
                <v-text-field
                  v-model="game.pickTeamLogo"
                  label="Pick Team Logo"
                />
              </v-flex>
              <v-flex xs12 sm3 mx-2>
                <v-text-field
                  v-model="game.customLogoStyle"
                  label="Pick Team Logo Style"
                />
              </v-flex>
              <v-flex xs12 sm3 mx-2>
                <v-text-field v-model="game.customLogo" label="Custom Logo" />
              </v-flex>
              <v-flex xs12 sm3 mx-2>
                <v-text-field
                  v-model="game.customLogoStyle"
                  label="Custom Logo Style"
                />
              </v-flex>
              <v-flex xs4 mx-2>
                <v-text-field
                  v-model="game.clientID"
                  label="Client ID"
                  required
                />
              </v-flex>
              <v-flex xs12 sm5 mx-2>
                <v-img
                  :src="game.image"
                  style="float: left; margin-right: 10px"
                  width="125"
                  @click="game.image = null"
                />
                <image-uploader
                  :debug="1"
                  :max-width="1200"
                  :quality="1"
                  :auto-rotate="true"
                  :preview="false"
                  :class-name="[
                    'fileinput',
                    { 'fileinput--loaded': game.image }
                  ]"
                  output-format="verbose"
                  capture="environment"
                  accept=".jpg,.png,.jpeg"
                  @input="setImage($event, game, 'image')"
                >
                </image-uploader>
              </v-flex>
            </v-layout>
          </div>
        </v-tab-item>
        <v-tab-item key="match-emails" value="match-emails">
          <GameSettingsMatchEmails v-model="game.matchEmails">
          </GameSettingsMatchEmails>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-center>
        <!-- <v-flex v-if="copying" xs3>
          <v-switch v-model="game.copyPlay" label="Copy Play Data" />
        </v-flex> -->
        <v-btn v-if="copying" flat class="primary" @click="update">Copy</v-btn>
        <v-btn
          v-else
          :loading="loadingImage"
          flat
          class="primary"
          @click="update"
          >Save</v-btn
        >
        <v-btn flat class="secondary" @click="close()">Cancel</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import uniqid from "uniqid"
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"
import Datepicker from "vuejs-datepicker"
import GameSettingsMatchEmails from "./GameSettingsMatchEmails"
//import { mapState } from "vuex"
import { mapGetters } from "vuex"
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
import * as moment from "moment"

import StylesEditor from "@/modules/theme/components/StylesEditor.vue"
import { DEFAULT_THEME_STYLES } from "@/modules/theme/constants"

import { SelectManager } from "@/helpers"

const MILISECONDS_IN_MINUTE = 60000

export default {
  metaInfo: {
    title: "Games"
  },
  components: {
    VueTimepicker,
    Datepicker,
    GameSettingsMatchEmails,
    StylesEditor
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + "..."
      }
      return value
    }
  },
  async created() {
    if (!this.game.name) {
      this.game.name = "New Game"
      this.game.players = 30
      this.game.potato = 15
      this.locked = true
      this.gameType = "Standard"
      this.onDeck = true
      this.game.runStatus = "User Templates"
    }
    console.log("GAME CREATE", this.game)
    await this.$store.dispatch("fetchHosts")
    if (this.game.endTimestamp) {
      this.endTimeText = moment(this.endTimestamp).format("LLLL")
    }
    if (this.game.startTimestamp) {
      const date = new Date(this.game.startTimestamp)
      this.gameDate = date
      const hours = ("0" + date.getHours()).substr(-2)
      const minutes = ("0" + date.getMinutes()).substr(-2)
      const seconds = ("0" + date.getSeconds()).substr(-2)
      this.gameTime = {
        HH: hours,
        mm: minutes,
        ss: seconds
      }
    }
    if (this.game.endTimestamp) {
      const date = new Date(this.game.endTimestamp)
      this.gameDate = date
      const hours = ("0" + date.getHours()).substr(-2)
      const minutes = ("0" + date.getMinutes()).substr(-2)
      const seconds = ("0" + date.getSeconds()).substr(-2)
      this.gameTime = {
        HH: hours,
        mm: minutes,
        ss: seconds
      }
    }
    this.gameType = this.game.gameType
  },
  data() {
    return {
      copyToOrgID: null,
      endTimeText: null,
      endSwitch: false,
      gameDate: new Date(Date.now()),
      gameTime: {
        HH: "00",
        mm: "00",
        ss: "00"
      },
      activeSettingsTab: null,
      tries: ["Unlimited", "1", "2", "3", "4", "5"],
      tab: 0,
      tabs: [
        "All",
        "Client Games",
        "Testing",
        "Templates",
        "User Templates",
        "Blocks",
        "Masters",
        "Tournament",
        "Archived",
        "Deleted"
      ],
      listTabs: [
        "Client Games",
        "Testing",
        "Templates",
        "User Templates",
        "Blocks",
        "Masters",
        "Tournament",
        "Archived",
        "Deleted"
      ],
      hasImage: false,
      imageName: "",
      imageUrl: "",
      imageFile: "",
      gameType: "Standard",
      loadingImage: false,
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      tmp: "",
      search: "",
      pagination: {},
      behaviors: this.$store.getters.behaviors,
      playTypes: this.$store.getters.playTypes,
      playType: "Team: Speed Matters",
      headers: [
        {
          sortable: false
        },
        {
          text: "Game",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "Date",
          value: "date",
          align: "left",
          sortable: false
        },
        {
          text: "Client",
          value: "client",
          align: "left",
          sortable: false
        },

        {
          text: "Players",
          value: "players",
          align: "left",
          sortable: false
        },

        {
          text: "Game Login",
          value: "code",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ]
    }
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    copying: {
      type: Boolean,
      required: false
    },
    adding: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(["theOrg"]),
    ...mapGetters("auth", ["token"]),
    themes() {
      return this.theOrg && this.theOrg.themes
        ? SelectManager.normalize(this.theOrg.themes, "name")
        : []
    },
    gameTypes() {
      //console.log("Game Types", this.$store.getters["Games/gameTypes"])
      return this.$store.getters["Games/gameTypes"]
    },
    startTimestamp() {
      const date = new Date(this.gameDate)
      const { HH, mm, ss } = this.gameTime
      const hours = parseInt(HH)
      const minutes = parseInt(mm)
      const seconds = parseInt(ss)

      date.setHours(hours)
      date.setMinutes(minutes)
      date.setSeconds(seconds)

      return date.getTime()
    },
    user() {
      return this.$store.getters.user
    },
    orgID() {
      return this.$store.getters.orgID
    },
    hosts() {
      var arr = Object.values(this.$store.getters.hosts)
      arr = arr.filter(item => item.orgID == this.orgID && !item.deleted)
      arr = arr.sort((a, b) => {
        var textA = a.firstname.toUpperCase()
        var textB = b.firstname.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      for (var i in arr) {
        arr[i].theName = arr[i].firstname + " " + arr[i].lastname
      }
      return arr
    },
    orgs() {
      console.log("orgs")
      var arr = Object.entries(this.$store.getters.orgs)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name
        newArr.push(obj)
      }
      newArr.push({ id: 0, name: "All Organizations" })
      console.log("orgs end")
      return newArr
    },
    maxTeamSize() {
      var p = []
      for (var i = 1; i < 16; i++) {
        p.push({ name: i, value: i })
      }
      return p
    },
    playerPointsGoal() {
      var p = []
      for (var i = 0; i < 2001; i = i + 25) {
        p.push(i)
      }
      return p
    },
    maxNumOfTeams() {
      var p = []
      for (var i = 1; i < 20; i++) {
        p.push({ name: i, value: i })
      }
      return p
    },
    normalizedGameDuration: {
      get() {
        return typeof this.game.duration === "number"
          ? this.game.duration / MILISECONDS_IN_MINUTE
          : undefined
      },
      /**
       * @param {number} value
       */
      set(value) {
        this.game.duration = value * MILISECONDS_IN_MINUTE
      }
    }
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  methods: {
    logout() {
      this.$router.push("/logout")
    },
    close() {
      // console.log("CLOSING")
      this.$emit("closeGameSettings")
    },
    numOfPlayers(p) {
      console.log(p)
      if (p.activePlayers > 0) return p.activePlayers + "/" + p.players
      if (!p.activePlayers) return p.players
    },
    async setImage(e, game) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.user.username}.png`
        game.image = await uploadMedia({ fileName, blob, token: this.token })
        console.log("which type = " + game.image)
        this.hasImage = true
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    resetForm() {
      this.game = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(game) {
      this.viewing = true
      this.game = game
    },
    closeModalDetail() {
      this.viewing = false
      this.game = null
    },
    openRemoveDialog(game) {
      this.game = game
      this.deleting = true
    },
    remove() {
      this.$store.dispatch("Games/removeGame", this.game)
      this.deleting = false
    },
    edit(game) {
      this.copying = false
      this.editing = true
      this.game = game
    },
    closeEdit() {
      this.copying = false
      this.editing = false
    },
    copy(game) {
      this.copying = true
      this.editing = true
      this.game = game
    },
    add() {
      this.editing = true
      this.adding = true
      this.game = {
        name: "",
        description: "",
        group: true,
        numOfPlayers: 30,
        potato: 15
      }
      console.log("THE GAME", this.game)
    },
    async update() {
      console.log("updating game")
      console.log(this.game)

      // if (this.game.id && this.game.id !== this.game.theKey) {
      //   alert("Game ID error! Please contact your administrator")
      //   console.error(
      //     `Game ID error! Please contact your administrator. ${this.game.id} vs ${this.game.theKey}`
      //   )
      //   return
      // }

      this.game.addedBy = this.user.name
      if (this.endSwitch) this.game.endTimestamp = null
      if (this.gameType) this.game.gameType = this.gameType
      if (!this.game.theKey) this.game.theKey = this.game.id
      this.game.startTimestamp = this.startTimestamp
      if (this.adding) {
        this.$store.dispatch("Games/addGame", this.game)
      } else if (this.copying) {
        if (this.copyToOrgID == 0) {
          var arr = Object.entries(this.$store.getters.orgs)
          console.log("ORGS", arr)
          for (var i in arr) {
            this.game.orgID = arr[i][0]
            // console.log("ORG TO COPY", arr[i][0])
            // console.log("THIS ORG", this.orgID)
            if (this.orgID != arr[i][0])
              await this.$store.dispatch("Games/copyGame", {
                game: this.game,
                orgID: arr[i][0]
              })
          }
        }
        await this.$store.dispatch("Games/copyGame", {
          game: this.game,
          orgID: this.copyToOrgID
        })
      } else {
        console.log("update")
        await this.$store.dispatch("Games/updateGame", this.game)
      }
      this.close()
      this.$emit("update")
    },
    /**
     * @param {import('@/types/game').Game} game
     * @returns {import('@/modules/theme/types').Styles}
     */
    getStyles(game) {
      return game.styles || DEFAULT_THEME_STYLES
    },
    /** @param {import('@/modules/theme/components/types').StylesEditorInputEventPayload} payload */
    onStylesInput(payload) {
      this.$set(this.game, "themeID", null)
      this.$set(this.game, "styles", payload.styles)
    },
    deleteTheme() {
      this.$set(this.game, "themeID", null)
    }
  }
}
</script>

<style lang="scss">
.liftup {
  margin-top: -20px;
}
.game-setting {
  .vdp-datepicker__calendar {
    width: 200px;
    .cell {
      height: 20px;
      line-height: 20px;
    }
  }
}
</style>
