var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "game-setting" },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "highlight", dark: "" } },
            [
              _vm.copying
                ? _c("v-icon", [_vm._v("open_in_new")])
                : _c("v-icon", { attrs: { dark: "" } }, [_vm._v("build")]),
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.game.name) + " ")
              ]),
              _c("v-spacer"),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.update } },
                [_c("v-icon", [_vm._v("saves")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("cancel")])],
                1
              )
            ],
            1
          ),
          _vm.game
            ? _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.activeSettingsTab,
                    callback: function($$v) {
                      _vm.activeSettingsTab = $$v
                    },
                    expression: "activeSettingsTab"
                  }
                },
                [
                  _c("v-tab", { key: "general", attrs: { href: "#general" } }, [
                    _vm._v("Overview")
                  ]),
                  _c("v-tab", { key: "details", attrs: { href: "#details" } }, [
                    _vm._v("Expo")
                  ]),
                  _c("v-tab", { key: "styles", attrs: { href: "#styles" } }, [
                    _vm._v("Styles")
                  ]),
                  _c(
                    "v-tab",
                    { key: "match-emails", attrs: { href: "#match-emails" } },
                    [_vm._v("RR Match Emails")]
                  ),
                  _c(
                    "v-tab-item",
                    { key: "general", attrs: { value: "general" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Internal Name",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "name", $$v)
                                      },
                                      expression: "game.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "External Name",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.externalName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "externalName", $$v)
                                      },
                                      expression: "game.externalName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.hosts,
                                      label: "Host",
                                      "item-text": "theName",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.game.hostUserID,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "hostUserID", $$v)
                                      },
                                      expression: "game.hostUserID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Description",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.description,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "description", $$v)
                                      },
                                      expression: "game.description"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.game.runStatus == "Masters"
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm1: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Version #" },
                                        model: {
                                          value: _vm.game.version,
                                          callback: function($$v) {
                                            _vm.$set(_vm.game, "version", $$v)
                                          },
                                          expression: "game.version"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Pick Team Text",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.pickTeamText,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "pickTeamText", $$v)
                                      },
                                      expression: "game.pickTeamText"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Later Comer Video" },
                                    model: {
                                      value: _vm.game.latecomerVideoCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.game,
                                          "latecomerVideoCode",
                                          $$v
                                        )
                                      },
                                      expression: "game.latecomerVideoCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Client", required: "" },
                                    model: {
                                      value: _vm.game.client,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "client", $$v)
                                      },
                                      expression: "game.client"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Game Date", required: "" },
                                    model: {
                                      value: _vm.game.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "date", $$v)
                                      },
                                      expression: "game.date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.listTabs,
                                      label: "Game Status",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.runStatus,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "runStatus", $$v)
                                      },
                                      expression: "game.runStatus"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Locked" },
                                    model: {
                                      value: _vm.game.locked,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "locked", $$v)
                                      },
                                      expression: "game.locked"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Started" },
                                    model: {
                                      value: _vm.game.started,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "started", $$v)
                                      },
                                      expression: "game.started"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "LogRocket" },
                                    model: {
                                      value: _vm.game.logrocket,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "logrocket", $$v)
                                      },
                                      expression: "game.logrocket"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Timer Off" },
                                    model: {
                                      value: _vm.game.timerOff,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "timerOff", $$v)
                                      },
                                      expression: "game.timerOff"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-switch",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        label: "Email Login"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.game.regularLogin,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.game,
                                                            "regularLogin",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "game.regularLogin"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2483739771
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Email Login can be enabled in Client Settings "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "liftup",
                                  attrs: { xs12: "", sm2: "", "mx-2": "" }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Disable Tips" },
                                    model: {
                                      value: _vm.game.tipsDisabled,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "tipsDisabled", $$v)
                                      },
                                      expression: "game.tipsDisabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "liftup",
                                  attrs: { xs12: "", sm2: "", "mx-2": "" }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Onboard Chat" },
                                    model: {
                                      value: _vm.game.onboardChat,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "onboardChat", $$v)
                                      },
                                      expression: "game.onboardChat"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Potato" },
                                    model: {
                                      value: _vm.game.potato,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "potato", $$v)
                                      },
                                      expression: "game.potato"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Num of players" },
                                    model: {
                                      value: _vm.game.players,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "players", $$v)
                                      },
                                      expression: "game.players"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("datepicker", {
                                    attrs: { placeholder: "Game Time" },
                                    model: {
                                      value: _vm.gameDate,
                                      callback: function($$v) {
                                        _vm.gameDate = $$v
                                      },
                                      expression: "gameDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("vue-timepicker", {
                                    attrs: { format: "HH:mm:ss" },
                                    model: {
                                      value: _vm.gameTime,
                                      callback: function($$v) {
                                        _vm.gameTime = $$v
                                      },
                                      expression: "gameTime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.logout()
                                        }
                                      }
                                    },
                                    [_vm._v("Logout")]
                                  )
                                ],
                                1
                              ),
                              !!_vm.endTimeText
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm2: "", "mx-2": "" }
                                    },
                                    [
                                      _vm._v(
                                        " End Time: " +
                                          _vm._s(_vm.endTimeText) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.user.super && _vm.copying
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.orgs,
                                      label: "Copy To Organization",
                                      "item-text": "name",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.copyToOrgID,
                                      callback: function($$v) {
                                        _vm.copyToOrgID = $$v
                                      },
                                      expression: "copyToOrgID"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-tab-item",
                    {
                      key: "styles",
                      staticClass: "pa-4",
                      attrs: { value: "styles" }
                    },
                    [
                      _c("StylesEditor", {
                        staticClass: "mb-2",
                        attrs: { styles: _vm.getStyles(_vm.game) },
                        on: { input: _vm.onStylesInput }
                      }),
                      _vm._v(" " + _vm._s(_vm.game.themeID) + " "),
                      _c("v-select", {
                        staticClass: "max-w-xs",
                        attrs: { items: _vm.themes, label: "Theme" },
                        model: {
                          value: _vm.game.themeID,
                          callback: function($$v) {
                            _vm.$set(_vm.game, "themeID", $$v)
                          },
                          expression: "game.themeID"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red" },
                          on: { click: _vm.deleteTheme }
                        },
                        [_vm._v("Delete Theme")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "details", attrs: { value: "details" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.gameTypes,
                                      label: "Game Type"
                                    },
                                    model: {
                                      value: _vm.gameType,
                                      callback: function($$v) {
                                        _vm.gameType = $$v
                                      },
                                      expression: "gameType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.gameType == "YouTube"
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm4: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          sm5: "",
                                          label: "YouTube Url"
                                        },
                                        model: {
                                          value: _vm.game.youTubeUrl,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "youTubeUrl",
                                              $$v
                                            )
                                          },
                                          expression: "game.youTubeUrl"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.gameType == "YouTube"
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm3: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-switch", {
                                        staticStyle: { "margin-top": "-3px" },
                                        attrs: { sm6: "", label: "Auto Play" },
                                        model: {
                                          value: _vm.game.youTubeAutoPlay,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "youTubeAutoPlay",
                                              $$v
                                            )
                                          },
                                          expression: "game.youTubeAutoPlay"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v("p "),
                              _vm.gameType == "Twitch"
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm8: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Twitch Channel" },
                                        model: {
                                          value: _vm.game.twitchChannel,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "twitchChannel",
                                              $$v
                                            )
                                          },
                                          expression: "game.twitchChannel"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Auditable" },
                                    model: {
                                      value: _vm.game.auditable,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "auditable", $$v)
                                      },
                                      expression: "game.auditable"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Deactivate" },
                                    model: {
                                      value: _vm.game.deactivate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "deactivate", $$v)
                                      },
                                      expression: "game.deactivate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Staging" },
                                    model: {
                                      value: _vm.game.ondeck,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "ondeck", $$v)
                                      },
                                      expression: "game.ondeck"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Duration Mins" },
                                    model: {
                                      value: _vm.normalizedGameDuration,
                                      callback: function($$v) {
                                        _vm.normalizedGameDuration = _vm._n($$v)
                                      },
                                      expression: "normalizedGameDuration"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Pick Team Logo" },
                                    model: {
                                      value: _vm.game.pickTeamLogo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "pickTeamLogo", $$v)
                                      },
                                      expression: "game.pickTeamLogo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Pick Team Logo Style" },
                                    model: {
                                      value: _vm.game.customLogoStyle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.game,
                                          "customLogoStyle",
                                          $$v
                                        )
                                      },
                                      expression: "game.customLogoStyle"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Custom Logo" },
                                    model: {
                                      value: _vm.game.customLogo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "customLogo", $$v)
                                      },
                                      expression: "game.customLogo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Custom Logo Style" },
                                    model: {
                                      value: _vm.game.customLogoStyle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.game,
                                          "customLogoStyle",
                                          $$v
                                        )
                                      },
                                      expression: "game.customLogoStyle"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Client ID", required: "" },
                                    model: {
                                      value: _vm.game.clientID,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "clientID", $$v)
                                      },
                                      expression: "game.clientID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                                [
                                  _c("v-img", {
                                    staticStyle: {
                                      float: "left",
                                      "margin-right": "10px"
                                    },
                                    attrs: {
                                      src: _vm.game.image,
                                      width: "125"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.game.image = null
                                      }
                                    }
                                  }),
                                  _c("image-uploader", {
                                    attrs: {
                                      debug: 1,
                                      "max-width": 1200,
                                      quality: 1,
                                      "auto-rotate": true,
                                      preview: false,
                                      "class-name": [
                                        "fileinput",
                                        { "fileinput--loaded": _vm.game.image }
                                      ],
                                      "output-format": "verbose",
                                      capture: "environment",
                                      accept: ".jpg,.png,.jpeg"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setImage(
                                          $event,
                                          _vm.game,
                                          "image"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-tab-item",
                    { key: "match-emails", attrs: { value: "match-emails" } },
                    [
                      _c("GameSettingsMatchEmails", {
                        model: {
                          value: _vm.game.matchEmails,
                          callback: function($$v) {
                            _vm.$set(_vm.game, "matchEmails", $$v)
                          },
                          expression: "game.matchEmails"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _vm.copying
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { flat: "" },
                      on: { click: _vm.update }
                    },
                    [_vm._v("Copy")]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { loading: _vm.loadingImage, flat: "" },
                      on: { click: _vm.update }
                    },
                    [_vm._v("Save")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "secondary",
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }